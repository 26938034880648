import { IActionCreator } from 'store/types/store.types';
import { IContactTypeReduxState } from '../contactTypes.types';
import { ContactTypeTypes, } from "./actions-types";

const initialState: IContactTypeReduxState = {
    items: null,
    item: null,
    nameOptions:[]
}

export const ContactTypeReducer = (state = initialState, { type, payload }: IActionCreator) => {
    switch (type) {
        case ContactTypeTypes.GET_CONTACT_TYPE_SUCCESS:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}