import { IPublicReduxState } from '../types/public';
import { LeftMenuActionTypes, publicConstants } from "./action-types";

const initialState: IPublicReduxState = {
    leftMenuOpen: true,
    langs: null,
    langsActive: 'az',
    crumb: null
}

export const publicReducer = (state = initialState, { type, payload }: any) => {
    switch (type) {
        case LeftMenuActionTypes.TOGGLE_LEFT_MENU:
            return {
                ...state,
                leftMenuOpen: payload
            }
        case publicConstants.LANG_SUCCESS:
            return {
                ...state,
                langs: payload
            }
        case publicConstants.LANG_TOGGLE:
            return {
                ...state,
                langsActive: payload
            }
        case publicConstants.SET_CRUMB:
            return {
                ...state,
                crumb: payload
            }
        default:
            return state;
    }
}
