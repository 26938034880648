import { loading } from "core/layouts/public/store/actions";
import { Dispatch } from "redux";
import { IActionCreator } from "store/types/store.types";
import { container } from "tsyringe";
import { IContactType } from "../contactTypes.types";
import { ContactTypeServices } from "../services/contactTypes.service";
import { ContactTypeTypes } from "./actions-types";
import history from "core/configs/history.config";
import Swal from "sweetalert2";


const service = container.resolve(ContactTypeServices);

export const getContactTypeSuccess = (Contact: IContactType[]) => ({
  type: ContactTypeTypes.GET_CONTACT_TYPE_SUCCESS,
  payload: Contact,
});

export const getContactType = () => (dispatch: Dispatch<IActionCreator>) => {
  dispatch(loading(true));
  service
    .getContactType()
    .then((res) => {
      dispatch(getContactTypeSuccess(res));
    })
    .catch((err) => console.error(err))
    .then(() => dispatch(loading(false)));
};

export const getContactTypeById =
  (id: string | number) => (dispatch: Dispatch<IActionCreator>) => {
    dispatch(loading(true));
    service
      .getContactTypeId(id)
      .then((res) => {
        dispatch(getContactTypeSuccess(res));
      })
      .catch((err) => console.error(err))
      .then(() => dispatch(loading(false)));
  };

export const putContactType =
  (data: any, id: number | undefined) => (dispatch: Dispatch<any>) => {
    dispatch(loading(true));
    service
      .putContactType(data, id)
      .then((res) => {
        // dispatch(getContactType());
          if(res.status === 204){
              Swal.fire({
                  title: 'Dəyişiklik qeydə alındı.',
                  icon: 'success',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 1500
              })
              history.goBack()
          }
      })
      .catch((err) => console.error(err))
      .then(() => dispatch(loading(false)));
  };
