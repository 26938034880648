import { IActionCreator } from 'store/types/store.types';
import { ICategoriesReduxState } from '../categories.types';
import { CategoriesTypes, } from "./action-types";

const initialState: ICategoriesReduxState = {
    items: null,
    paging: null,
    item: null,
    searchParams: ''
}

export const CategoryReducer = (state = initialState, { type, payload }: IActionCreator) => {
    switch (type) {
        case CategoriesTypes.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                ...payload
            }
        case CategoriesTypes.SET_CATEGORIES_SEARCH:
            return {
                ...state,
                searchParams: payload
            }
        case CategoriesTypes.GET_CATEGORY_ID_SUCCESS:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}
