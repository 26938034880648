import { IActionCreator } from "store/types/store.types";
import { IContentReduxState } from "../content.types";
import { ContentTypes } from "./actions-types";

const initialState: IContentReduxState = {
  items: null,
  item: null,
};

export const ContentReducer = (
  state = initialState,
  { type, payload }: IActionCreator
) => {
  switch (type) {
    case ContentTypes.GET_CONTENT_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case ContentTypes.GET_CONTENT_BY_ID_SUCCESS:
      return {
        ...state,
        ...payload,
      };
      case ContentTypes.GET_CONTENT_TYPE_SUCCESS:
        return {
          ...state,
          ...payload,
        };
    default:
      return state;
  }
};
