import { IActionCreator } from 'store/types/store.types';
import { IAboutReduxState } from '../about.types';
import { AboutTypes, } from "./action-types";

const initialState: IAboutReduxState = {
    items: null,
    paging: null,
    item: null
}

export const AboutReducer = (state = initialState, { type, payload }: IActionCreator) => {
    switch (type) {
        case AboutTypes.GET_ABOUT_SUCCESS:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}
