import { API } from "core/configs/api.config";
import { getToken } from "core/configs/auth.config";
import { DELETE } from "packages/VHttp/DELETE";
import { GET } from "packages/VHttp/GET";
import { POST } from "packages/VHttp/POST";
import { PUT } from "packages/VHttp/PUT";
import { IPageParams } from "store/types/store.types";
import { container, injectable } from "tsyringe";
import { OrganisationModel } from "../models/organisation.model";
import { OragnisationByIdModel } from "../models/organisationById.model";

@injectable()
export class OrganisationServices {
  private _get: GET = container.resolve(GET);
  private _post: POST = container.resolve(POST);
  private _put: PUT = container.resolve(PUT);
  private _delete: DELETE = container.resolve(DELETE);

  getOrganisation(params: IPageParams, name: any): Promise<any> {
    return this._get
      .setApi(API.organisation)
      .setParams({ ...params, ...name })
      .requestPromise()
      .then((res) => {
        return new OrganisationModel(res.data);
      });
  }

  getOrganisationId(id: number | string): Promise<any> {
    return this._get
      .setApi(API.organisation + "/" + id)
      .setParams(id)
      .requestPromise()
      .then((res) => {
        return new OragnisationByIdModel(res.data);
      });
  }

  postOrganisation(data: any): Promise<any> {
    return this._post
      .setApi(API.organisation)
      .setPayload(data)
      .requestPromise()
      .then((res) => {
        return new OrganisationModel(res.data);
      });
  }

  putOrganisation(data: any, id: number | undefined): Promise<any> {
    return this._put
      .setApi(API.organisation + `/${id}`)
      .setPayload(data)
      .requestPromise()
    // .then((res) => {
    //   // return new OrganisationModel(res.data);
    // });
  }

  deleteOrganisation(id: number): Promise<any> {
    return this._delete.setApi(API.organisation + `/${id}`)
      .requestPromise();
  }
}
