import { IPaging } from "store/types/store.types";
import { IContactType } from "../contactTypes.types";
import { ITableItems } from "../../../packages/RCRUD/types";

export class ContactTypeModel {
  public items: IContactType[] | null = null;
  public nameOptions: { value: number; title: string }[] | [] = [];

  constructor(item: any) {
    this._setItems(item);
    this._setNameOptions(item);
  }

  /**
   * Set items
   * @param items
   * @private
   */

  private _setItems({ contactTypes }: any): void {
    this.items = contactTypes;
  }

  /**
   * Set nameOptions
   * @param nameOptions
   * @private
   */

  private _setNameOptions({ contactTypes }: any): void {
    const newItems: { value: number; title: string }[] = [];
    contactTypes.map((item: IContactType) => {
      newItems.push({
        value: item.id,
        title: item.name,
      });
    });
    this.nameOptions = newItems;
  }
}
