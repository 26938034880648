export const table_keys:{[x:string]:string} = {
    CategoryPhoto: 'Kateqoriya ikonu',
    ContactTypeId: 'Kontakt növü',
    contentTypeId: 'Kontent növü',
    name: 'Ad',
    contactTypePhoto: 'Kontakt növü ikonu',
    OrganisationPhoto: 'Qurum ikonu',
    title: 'Başlıq',
    description: 'Təsvir',
    text:"Mətn",
    typeId:"Növ",
    categories:"Kateqoriya",
    email: 'Email',
    type: 'Növ',
    count: 'Say'
}