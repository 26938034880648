import React from 'react';
import './card.component.scss';

const CardComponent = (props : any)=>{
        return (
            <div className="card">
                {props.children}
            </div>
        );
}

export default CardComponent;
