import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { table_keys } from "packages/RCRUD/table_keys";
import React, { ChangeEvent } from "react";

export function GetLang(): any {
  return localStorage.getItem("opda_lang");
}

export const debounce = (func: any, wait: number) => {
  let timeout: any;
  return function executedFunction(...args: any) {
    const later = () => {
      timeout = null;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const generateGuid = () => {
  let dt = new Date().getTime();
  const guid = "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return guid;
};

export const recursiveLister = (
  rows: any[],
  childColumn: string = "",
  superParentValue: any = null
) => {
  const parents: any[] = [];
  const noParent: any[] = [];
  const arr = JSON.parse(JSON.stringify(rows));
  arr.forEach((item: any, index: number) => {
    if (item[childColumn] !== superParentValue) {
      if (arr.find((it: any) => it.id === item[childColumn])) {
        arr.forEach((datum: any) => {
          if (datum.id === item[childColumn]) {
            if (datum.children && Array.isArray(datum.children)) {
              datum.children.push(item);
            } else {
              datum.children = [item];
            }
          }
        });
      } else {
        noParent.push(item);
      }
    }
  });
  arr.forEach((item: any) => {
    if (item[childColumn] === superParentValue) {
      parents.push(item);
    }
  });
  noParent.forEach((item) => {
    parents.push(item);
  });
  return parents;
};

export function mapFormItems(
  inputs: any,
  callback: any,
  validCallback: any,
  column: string,
 inputStyle: string,
) {
  return Object.keys(inputs).map((input, index) => {
    let currentInput: any = inputs[input];
    if (currentInput.type === "select") {
      return (
        <div key={currentInput.label + currentInput.second_label} className={`px-16 py-10 `}>
          <p className={`second-label`}>{currentInput.second_label}</p>
          <label className={`r-label`}>{table_keys[currentInput.label]}</label>
          <select
            onChange={(e) => {
              changeInputValue(e, input, inputs, callback);
            }}
            defaultValue={currentInput.value}
            key={inputStyle==="static"?currentInput.value:''}
            className={`r-select py-10`}
          >
            <option disabled value="">Seç</option>
            {currentInput.options.map((opt: any) => {
              return <option key={opt.value} value={opt.value}>{opt.title}</option>;
            })}
          </select>
          <span className={"err-txt"}>
            {!currentInput.isValid ? currentInput.currentErr : null}
          </span>
        </div>
      );
    } else if (currentInput.type === "textarea") {
      return (
        <div key={currentInput.label + currentInput.second_label} className={` py-10 ${column}`}>
          {currentInput.icon ? (
            <FontAwesomeIcon className="" icon={currentInput.icon} />
          ) : null}

          <p className={"second-label"}>{currentInput.second_label}</p>
          <label className={"r-label"}>{table_keys[currentInput.label]}</label>
          <textarea
            onChange={(e) => {
              changeInputValue(e, input, inputs, callback);
            }}
            className={`r-textarea`}
          >
            {currentInput.value}
          </textarea>
          <span className={"err-txt"}>
            {!currentInput.isValid && currentInput.touched
              ? currentInput.currentErr
              : null}
          </span>
        </div>
      );
    } else if (currentInput.type === "file") {
      return (
        <div key={currentInput.label + currentInput.second_label} className={`px-16 py-10 col-12 mt-15`}>
          {currentInput.icon ? (
            <FontAwesomeIcon
              className=" mr-3"
              icon={currentInput.icon}
              size="xs"
            />
          ) : null}
          <p className={"second-label"}>{currentInput.second_label}</p>
          {/* <img src={currentInput.value} alt="" /> */}
          <label className={"r-label"}>{table_keys[currentInput.label]}</label>

          <div className="file-btn btn my-10 ">
            <label htmlFor="form_input">Choose file</label>
            <div className="file-input">
              <input
                onChange={(e) => {
                  changeFileInputValue(e, input, inputs, callback);
                }}
                id="form_input"
                type={currentInput.type}
                className={`r-input mt-8 px-16  `}
                placeholder={currentInput.placeholder}
                required
              />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-upload"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
            </svg>
          </div>

          <span className={"err-txt"}>
            {!currentInput.isValid && currentInput.touched
              ? currentInput.currentErrTxt
              : null}
          </span>
        </div>
      );
    } else {
      return (
        <div
          key={currentInput.label + currentInput.second_label}
          className={`px-16 py-10 ${column}`}
        >
          {currentInput.icon ? (
            <FontAwesomeIcon
              className=" mr-3"
              icon={currentInput.icon}
              size="xs"
            />
          ) : null}
          <p className={"second-label"}>{currentInput.second_label}</p>
          <label className={"r-label"}>{table_keys[currentInput.label]}</label>
          {currentInput.type === "password" ? (
            <input
              onChange={(e) => {
                changeInputValue(e, input, inputs, callback);
              }}
              required
              // onBlur={(e) => {
              //   inputBlur(e, input, inputs, callback, validCallback);
              // }}
              type={currentInput.visible ? "text" : "password"}
              className={`r-input mt-8 px-16 py-10`}
              placeholder={currentInput.placeholder}
              key={inputStyle==="static"?currentInput.value:''}
              defaultValue={currentInput.value}
            />
          ) : (
            <input
              onChange={(e) => {
                changeInputValue(e, input, inputs, callback);
              }}
              required
              // onBlur={(e) => {
              //   inputBlur(e, input, inputs, callback, validCallback);
              // }}
              type={currentInput.type}
              className={`r-input mt-8 px-16 py-7`}
              placeholder={currentInput.placeholder}
              key={inputStyle==="static"?currentInput.value:''}
              defaultValue={currentInput.value}
            />
          )}
          <span className={"err-txt"}>
            {!currentInput.isValid && currentInput.touched
              ? currentInput.currentErrTxt
              : null}
          </span>
        </div>
      );
    }
  });
}

export function changeInputValue(
  event: ChangeEvent<any>,
  input: string,
  state: any,
  callback: any,
  stateKey: string = "inputs"
) {
  const prevInput: any = { ...state[input] };
  prevInput.value = event.target.value;
  prevInput.touched = true;
  prevInput.currentErrTxt = "";
  callback({
    [`${prevInput.label}${prevInput.second_label !== ""
        ? "_" + prevInput.second_label
        : prevInput.second_label
      }`]: prevInput.value,
  });
}
export function changeFileInputValue(
  event: ChangeEvent<any>,
  input: string,
  state: any,
  callback: any,
  stateKey: string = "inputs"
) {
  const prevInput: any = { ...state[input] };
  let file = event.target.files[0];
  prevInput.value = file;
  prevInput.touched = true;
  prevInput.currentErrTxt = "";
  callback({ [`${prevInput.label}`]: prevInput.value });
}

export function inputBlur(
  event: ChangeEvent<any>,
  input: string,
  state: any,
  callback: any,
  validCallback: any
) {
  callback((prev: any) => {
    const prevInput = { ...prev[input] };
    prevInput.isValid = checkInputValidation(
      prevInput.value,
      prevInput.validation,
      prevInput,
      state
    );
    let formValid = true;
    Object.keys(state).map((item) => {
      formValid = state[item].isValid && formValid;
    });
    return {
      ...prev,
      [input]: prevInput,
    };
  });

  validCallback(() => {
    let formValid = true;
    Object.keys(state).map((item) => {
      formValid = state[item].isValid && formValid;
    });
    return formValid;
  });
}

export function checkInputValidation(
  value: any,
  rules: any,
  input: any,
  inputState: any
) {
  let isValid = true;
  const xssReg = new RegExp(/(\b)(on\S+)(\s*)=|javascript|<(|\/|[^\/>][^>]+|\/[^>][^>]+)>/);
  const thisValid = !xssReg.test(value.trim());
  isValid = thisValid && isValid;
  if (!thisValid) {
    input.currentErrTxt = 'Düzgün format istifadə edin';
  }
  if (rules.regexp) {
    if (value.trim().length > 0) {
      const exp = rules.regexp.value;
      if (!Array.isArray(exp)) {
        const reg = new RegExp(exp);
        const thisValid = reg.test(value.trim());
        isValid = thisValid && isValid;
        if (!thisValid) {
          input.currentErrTxt = rules.regexp.errorText;
        }
      } else {
        exp.forEach((item: any) => {
          const reg = new RegExp(item.exp);
          const thisValid = reg.test(value.trim());
          isValid = thisValid && isValid;
          if (!thisValid) {
            input.currentErrTxt = item.errorText;
          }
        });
      }
    }
  }
  if (rules.minLength) {
    const thisValid =
      value.trim().length >= Number(rules.minLength.value) ||
      value.trim() === "";
    isValid = thisValid && isValid;
    if (!thisValid) {
      input.currentErrTxt = rules.minLength.errorText;
    }
    // if(thisValid){
    //     input.currentErrTxt = ""
    // }
  }
  if (rules.maxLength) {
    const thisValid = value.trim().length <= Number(rules.maxLength.value);
    isValid = thisValid && isValid;
    if (!thisValid) {
      input.currentErrTxt = rules.maxLength.errorText;
    }
    // if(thisValid){
    //     input.currentErrTxt = ""
    // }
  }
  if (rules.isEqualToPass) {
    isValid = value === inputState.password.value && isValid;
    if (!isValid) {
      input.currentErrTxt = rules.isEqualToPass.errorText;
    }
  }
  if (rules.required) {
    const thisValid = typeof value === "object" ? true : value?.trim() !== "";
    isValid = thisValid && isValid;
    if (!thisValid) {
      input.currentErrTxt = rules.required.errorText;
    }
  }

  return isValid;
}

export const checkClaims = (userClaims: any[] = [], claim: string = "") => {
  return userClaims?.includes(claim);
};

export const renderDateFormat = (date: any, inputFormat: boolean = false) => {
  const dateFormat = new Date(date);
  let dayFormat: any = "";
  let mothFormat: any = "";
  let yearFormat: any = "";
  if (dateFormat.getMonth() >= 9) {
    mothFormat = dateFormat.getMonth() + 1;
  } else {
    mothFormat = "0" + (dateFormat.getMonth() + 1);
  }
  if (dateFormat.getDate() >= 10) {
    dayFormat = dateFormat.getDate();
  } else dayFormat = "0" + dateFormat.getDate();
  yearFormat = dateFormat.getFullYear();

  return inputFormat
    ? yearFormat + "-" + mothFormat + "-" + dayFormat
    : dayFormat + "." + mothFormat + "." + yearFormat;
};

export const extractTime = (date: any) => {
  let hours: any = "";
  let minutes: any = "";

  const dateFormat = new Date(date);
  if (dateFormat.getHours() <= 9) {
    hours = `0${dateFormat.getHours()}`;
  } else {
    hours = dateFormat.getHours();
  }

  if (dateFormat.getMinutes() <= 9) {
    minutes = `0${dateFormat.getMinutes()}`;
  } else {
    minutes = dateFormat.getMinutes();
  }
  return `${hours}:${minutes}`;
};
