import { IActionCreator } from "store/types/store.types";
import { IKeywordReduxState } from "../keyword.types";
import { KeywordTypes } from "./actions-types";

const initialState: IKeywordReduxState = {
  items: null,
  item: null,
  nameOptions:[],
  searchParams: ''
};

export const KeywordReducer = (
  state = initialState,
  { type, payload }: IActionCreator
) => {
  switch (type) {
    case KeywordTypes.GET_KEYWORD_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case KeywordTypes.SET_SEARCH:
      return {
        ...state,
        searchParams: payload
      }
    case KeywordTypes.GET_KEYWORD_BY_ID_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
