import { Redirect, Route } from "react-router-dom";
import { lazy, useEffect } from "react";
import CustomSwitchComponent from "./custom-switch/custom-switch.component";
import { getToken } from "../core/configs/auth.config";
import { useDispatch, useSelector } from "react-redux";
import { checkClaims } from "../core/helpers/common-functions/common-functions";
import OrganisationsComponent from "pages/organisations/organisations.component";
import EditComponent from "pages/contactTypes/components/secondary.components";



const PublicLayout = lazy(
  () => import("../core/layouts/public/public-layout.component")
);
const AuthComponent = lazy(() => import("../core/layouts/auth/auth.component"));
const HomeComponent = lazy(() => import("../pages/home/home.component"));
const LoginComponent = lazy(() => import("../pages/login/login.component"));
const RegisterComponent = lazy(
  () => import("../pages/register/register.component")
);
const ErrorComponent = lazy(
  () => import("../core/layouts/error/error.component")
);
const CreateOrganisationComponent = lazy(
  () => import("pages/organisations/components/secondary.component")
);

const NotFound = lazy(() => import("../pages/not-found/notfound.component"));
const CategoriesComponent = lazy(
  () => import("pages/categories/categories.component")
);
const CategorySecondaryComponent = lazy(
  () => import("pages/categories/components/secondary.component")
);
const FAQComponent = lazy(() => import("pages/faq/faq.component"));
const FAQSecondaryComponent = lazy(
  () => import("pages/faq/components/secondary.component")
);

const TagComponent = lazy(() => import("pages/tag/tag.component"));
const TagSecondaryComponent = lazy(
    () => import("pages/tag/components/secondary.component")
);
const ContactFormTypeComponent = lazy(() => import("pages/contact-form-types/contactFormType.component"));
const ContactFormTypeSecondaryComponent = lazy(
    () => import("pages/contact-form-types/components/secondary.component")
);
const AboutComponent = lazy(() => import("pages/about/about.component"));
const CreateEditComponent = lazy(() => import("pages/about/components/secondary.component"));
const ContactTypeComponent = lazy(() => import("pages/contactTypes/contactTypes.component"));
const ContactComponent = lazy(() => import("pages/contacts/contact.component"))
const SecondaryComponent = lazy(() => import("pages/contacts/components/secondary.component"))
const KeywordComponent = lazy(() => import("pages/keywords/keyword.component"));
const KeywordSecondaryComponent = lazy(() => import("pages/keywords/components/secondary.component"))
const ContentComponent = lazy(() => import("pages/content/content.component"))
const ContentCreateComponent = lazy(() => import("pages/content/components/secondary.component"))
const ServiceComponent = lazy(() => import("pages/service/service.component"))
const ServiceForm = lazy(() => import("pages/service/components/secondary.component"))
const ServiceEdit = lazy(() => import('../pages/service/components/secondary-edit.component'))
const ContactFormComponent = lazy(() => import('../pages/contact-form/contact-form.component'))

const layoutRouteList = [
  {
    component: PublicLayout,
    exact: false,
    path: "/app",
  },
  {
    component: AuthComponent,
    exact: false,
    path: "/auth",
  },
  {
    component: ErrorComponent,
    exact: false,
    path: "/error",
  },
];

const publicRoutes = [
  {
    component: HomeComponent,
    exact: true,
    path: "/app/home",
    name: "Əsas Səhifə",
    claims: [],
  },
  {
    component: CategoriesComponent,
    exact: true,
    path: "/app/categories",
    name: "Kateqoriyalar",
    claims: [],
  },
  {
    component: CategorySecondaryComponent,
    exact: true,
    path: "/app/categories/edit",
    name: "Kateqoriyalar / Redaktə et",
  },
  {
    component: CategorySecondaryComponent,
    exact: true,
    path: "/app/categories/create",
    name: "Kateqoriyalar / Əlavə et",
  },
  {
    component: FAQComponent,
    exact: true,
    path: "/app/faq",
    name: "FAQ",
    claims: [],
  },
  {
    component: FAQSecondaryComponent,
    exact: true,
    path: "/app/faq/edit",
    name: "FAQ / Redaktə et",
  },
  {
    component: FAQSecondaryComponent,
    exact: true,
    path: "/app/faq/create",
    name: "FAQ / Əlavə et",
  },
  {
    component: TagComponent,
    exact: true,
    path: "/app/tag",
    name: "Təq",
    claims: [],
  },
  {
    component: TagSecondaryComponent,
    exact: true,
    path: "/app/tag/edit",
    name: "Təq / Redaktə et",
  },
  {
    component: TagSecondaryComponent,
    exact: true,
    path: "/app/tag/create",
    name: "Təq / Əlavə et",
  },

  {
    component: OrganisationsComponent,
    exact: true,
    path: "/app/organisations",
    name: "Qurumlar",
    claims: [],
  },
  {
    component: CreateOrganisationComponent,
    exact: true,
    path: "/app/organisations/edit",
    name: "Qurumlar / Redaktə et",
  },
  {
    component: CreateOrganisationComponent,
    exact: true,
    path: "/app/organisations/create",
    name: "Qurumlar / Əlavə et",
  },
  {
    component: AboutComponent,
    exact: true,
    path: "/app/about",
    name: "Haqqımızda",
    claims: [],
  },

  {
    component: CreateEditComponent,
    exact: true,
    path: "/app/about/edit",
    name: "Haqqımızda / Redaktə et",
  },
  {
    component: ContactTypeComponent,
    exact: true,
    path: "/app/contactType",
    name: "Konktakt növü",
    claims: [],
  },
  {
    component: EditComponent,
    exact: true,
    path: "/app/contactType/edit",
    name: "Konktakt növü / Redaktə et",
  },
  {
    component: ContactComponent,
    exact: true,
    path: "/app/contact",
    name: "Kontakt",
    claims: [],
  },
  {
    component: SecondaryComponent,
    exact: true,
    path: "/app/contact/create",
    name: "Kontakt / Əlavə et",
  },
  {
    component: SecondaryComponent,
    exact: true,
    path: "/app/contact/edit",
    name: "Kontakt / Redaktə et",
  },
  {
    component: KeywordComponent,
    exact: true,
    path: "/app/keyword",
    name: "Tərcümələr",
    claims: [],
  },
  {
    component: KeywordSecondaryComponent,
    exact: true,
    path: "/app/keyword/create",
    name: "Tərcümələr / Əlavə et",
  },
  {
    component: KeywordSecondaryComponent,
    exact: true,
    path: "/app/keyword/edit",
    name: "Tərcümələr / Redaktə et",
  },

  {
    component: ContentComponent,
    exact: true,
    path: "/app/content",
    name: "Kontent",
    claims: [],
  },
  {
    component: ContentCreateComponent,
    exact: true,
    path: "/app/content/create",
    name: "Kontent / Əlavə et",
  },
  {
    component: ContentCreateComponent,
    exact: true,
    path: "/app/content/edit",
    name: "Kontent / Redaktə et",
  },
  {
    component: ServiceComponent,
    exact: true,
    path: "/app/service",
    name: "Xidmətlər",
    claims: [],
  },
  {
    component: ServiceForm,
    exact: true,
    path: "/app/service/create",
    name: "Xidmətlər / Əlavə et",
  },
  {
    component: ServiceEdit,
    exact: true,
    path: "/app/service/edit/:id",
    name: "Xidmətlər / Redaktə et",
  },
  {
    component: ContactFormComponent,
    exact: true,
    path: "/app/contact-form",
    name: 'Kontakt Forum'
  },
  {
    component:ContactFormTypeComponent,
    exact: true,
    path: "/app/contact-form-types",
    name: 'Kontakt Forum Növləri',
    claims: []
  },
  {
    component: ContactFormTypeSecondaryComponent,
    exact: true,
    path: "/app/contact-form-types/edit",
    name: "Kontakt Forum Növləri/ Redaktə et",
  },
  {
    component: ContactFormTypeSecondaryComponent,
    exact: true,
    path: "/app/contact-form-types/create",
    name: "Kontakt Forum Növləri/ Əlavə et",
  },
];

const authRoutesList = [
  {
    component: LoginComponent,
    exact: true,
    path: "/auth/login",
  },
  {
    component: RegisterComponent,
    exact: true,
    path: "/auth/register",
  },
];

const errorRouteList = [
  {
    component: NotFound,
    exact: true,
    path: "/error/not-found",
  },
];

export const LayoutRoutes = () => {
  const token = getToken();
  const dispatch = useDispatch();
  useEffect(() => {
    // token && dispatch(user_data(token))
  }, [dispatch, token]);

  return (
    <>
      <CustomSwitchComponent key="LayoutRoutes">
        <Route exact path={"/"}>
          {!token ? <Redirect to="/auth" /> : <Redirect to={"/app"} />}
        </Route>
        {layoutRouteList.map((route) => (
          <Route
            {...route}
            component={() => <route.component />}
            key={route.path}
          ></Route>
        ))}
      </CustomSwitchComponent>
    </>
  );
};

export function PublicRoutes(props: any) {
  const userClaims = useSelector(({ auth }: any) => auth.user).Claims;
  const token = getToken();
  useEffect(() => { }, [userClaims]);
  return (
    <>
      <CustomSwitchComponent key="PublicRoutes" from={props.from} to={props.to}>
        {publicRoutes.map((route, index) => {
          const newRoute = (
            <Route
              key={route.name + route.path}
              {...route}
              component={(routeProps: any) => {
                const crumbs = publicRoutes
                  .filter((route: any) => {
                    return routeProps.match.path.includes(route.path);
                  })
                  .map(({ path, name, ...rest }) => {
                    let n;
                    const altName =
                      routeProps.location.state &&
                        routeProps.location.state.data &&
                        routeProps.location.state.data.name
                        ? (n = routeProps.location.state.data.name)
                        : (n = "");
                    name === "*" ? (n = altName) : (n = name);
                    return {
                      path: Object.keys(routeProps.match.params).length
                        ? Object.keys(routeProps.match.params).reduce(
                          (path, param) =>
                            path.replace(
                              `:${param}`,
                              routeProps.match.params[param]
                            ),
                          path
                        )
                        : path,
                      name: n,
                      ...rest,
                    };
                  });
                return (
                  <route.component {...route} {...routeProps} crumbs={crumbs} />
                );
              }}
            />
          );
          let hasClaims = true;
          route.claims?.forEach((cl) => {
            hasClaims = checkClaims(userClaims, cl) && hasClaims;
          });
          return token ? (
            // route.claims && route.claims?.length > 0 ?
            //     // route.claims?.map(cl => {
            //     //     return checkClaims(userClaims, cl) ?
            //     //         newRoute
            //     //         :
            //     //         null
            //     // })
            //     hasClaims ? newRoute : null
            //     :
            newRoute
          ) : (
            <Redirect to="/auth" />
          );
        })}
      </CustomSwitchComponent>
    </>
  );
}

export const AuthRoutes = (props: any) => {
  const token = getToken();
  return (
    <>
      <CustomSwitchComponent key="AuthRoutes" from={props.from} to={props.to}>
        {authRoutesList.map((route) =>
          !token ? (
            <Route
              {...route}
              component={(props: any) => <route.component {...props} />}
              key={route.path}
            ></Route>
          ) : (
            <Redirect to="/app" />
          )
        )}
      </CustomSwitchComponent>
    </>
  );
};

export const ErrorRoutes = (props: any) => {
  return (
    <>
      <CustomSwitchComponent key="ErrorRoutes" from={props.from} to={props.to}>
        {errorRouteList.map((route) => (
          <Route
            {...route}
            component={() => <route.component />}
            key={route.path}
          ></Route>
        ))}
      </CustomSwitchComponent>
    </>
  );
};
