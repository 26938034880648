import {
  generateGuid,
  GetLang,
} from "core/helpers/common-functions/common-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonComponent from "packages/RButton/button.component";
import CardComponent from "packages/RCard/card.component";
import ModalBody from "packages/RModal/components/body.component";
import ModalComponent from "packages/RModal/modal.component";
import TableComponent from "packages/RTable/table.component";
import React, {useCallback, useEffect, useState} from "react";
import "./components.component.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types/store.types";
import { ITableItems, ITableProps } from "./types";
import { ILang } from "core/layouts/public/types/public";
import { langToggle } from "core/layouts/public/store/actions";
import { Link, useLocation } from "react-router-dom";
import "./components.component.scss";
import { table_keys } from "./table_keys";
import DOMPurify from "dompurify";

const ReadComponent = ({
  data,
  contentKeys,
  staticKeys,
  deleteItem,
  showCreate,
    showEdit,
  pageParams
}: ITableProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const { langs, langsActive = langs[0].key } = useSelector(
    (state: RootState) => state.publicState
  );

  const { pathname } = useLocation();

  const tableBodyRenderer = useCallback(
    (data) => {
      return data?.map((row: ITableItems | any, index: number) => (
        <tr key={generateGuid()}>
          {pageParams &&
            <td>{ ((pageParams.PageNumber - 1) * pageParams.PageSize + index + 1)}</td>
          }
          {contentKeys &&
            contentKeys.map((key: string) => (
              <td key={generateGuid()}>
                {row?.content &&
                  row?.content
                    ?.map((i: any) => <p key={generateGuid()} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(i[key]) }} />)}
              </td>
            ))}
          {
            row?.contactTypeName && <td><span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.contactTypeName) }}></span></td>
          }
          {staticKeys?.map((key: string) => (
            <td key={generateGuid()}>
              {key.includes("Photo") ? (
                <img src={row[key]} alt="" className="table_icon" />
              ) : (
                <p key={generateGuid()} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row[key])}}></p>
              )}
            </td>
          ))}

          <td className="text-right edit_delete">
            {
              showEdit && (
                    <Link to={`${pathname}/edit?${row.id}`}>
                      <ButtonComponent
                          size="sm"
                          classNames="operation-btn"
                          outline={true}
                          color="success"
                      >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil"
                            viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                      </ButtonComponent>
                    </Link>
                )
            }
            {deleteItem && (
              <ButtonComponent
                click={() => {
                  setShow(true);
                  setId(row.id);
                }}
                size="sm"
                classNames="operation-btn"
                outline={true}
                color="danger"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </ButtonComponent>
            )}
          </td>
        </tr>
      ));
    },
    [contentKeys, deleteItem, langsActive, pageParams, pathname, showEdit, staticKeys]
  );
  return (
    <>
      <div className="d-flex justify-between align-center mb-30">
        {contentKeys.length ? (
          <ul className="lang_toggle_list">
            {/* {langs?.map((lang: ILang) => (
              <li
                key={generateGuid()}
                className={`lang_toggle_list_item ${
                  lang.key === langsActive && "active"
                }`}
                onClick={() => dispatch(langToggle(lang.key))}
              >
                {lang.label}
              </li>
            ))} */}
          </ul>
        ) : (
          true
        )}
        {showCreate !== false && (
          <Link to={`${pathname}/create`}>
            <ButtonComponent
              classNames="create-btn border btn btn-secondary"
              size="sm"
              outline={true}
              color="primary"
            >
              <FontAwesomeIcon icon="plus" />
            </ButtonComponent>
          </Link>
        )}
      </div>
      <CardComponent>
        <TableComponent>
          <TableComponent.Head>
            <tr>
              {pageParams && <th>#</th>}
              {contentKeys?.map((key: string) => (
                <th key={generateGuid()}>{table_keys[key]}</th>
              ))}
              {staticKeys?.map((key: string) => (
                <th key={generateGuid()}>{table_keys[key]}</th>
              ))}
              
              <th></th>
            </tr>
          </TableComponent.Head>
          <TableComponent.Body>{tableBodyRenderer(data)}</TableComponent.Body>
        </TableComponent>
        <ModalComponent
          size={"md"}
          title={"Məlumatı silməyə əminsinizmi?"}
          show={show}
          setShow={setShow}
        >
          <ModalBody>
            <div className="d-flex align-center justify-start mb-16">
              {/* <img src={data && id ? data.find((item: any) => item.id === id)?.icon : ''} alt="" /> */}
              <p className="m-0 ml-8">
                {/* {data && id && data.find((item: any) => item.id === id)?.content.find(item => item.languageId === langsActive).name} */}
              </p>
            </div>
            <div className="d-flex justify-between">
              <ButtonComponent
                size="sm"
                outline={true}
                color="success"
                click={() => setShow(false)}
                classNames="btn-primary m-10"
              >
                Xeyr
              </ButtonComponent>
              <ButtonComponent
                click={() => {
                  deleteItem && deleteItem(id);
                  setShow(false);
                }}
                size="sm"
                outline={true}
                color="danger"
                classNames="btn-danger m-10"
              >
                Bəli
              </ButtonComponent>
            </div>
          </ModalBody>
        </ModalComponent>
      </CardComponent>
    </>
  );
};

export default ReadComponent;
