import { IActionCreator } from 'store/types/store.types';
import {IContactFormTypesRedux} from '../contactFormType.types';
import { ContactFormTypes } from "./action-types";

const initialState: IContactFormTypesRedux = {
    items: null,
    paging: null,
    item: null,
    searchParams: ''
}

export const contactFormTypeReducer = (state = initialState, { type, payload }: IActionCreator) => {
    switch (type) {
        case ContactFormTypes.GET_CONTACT_FORM_TYPE_SUCCESS:
            return {
                ...state,
                ...payload
            }
        case ContactFormTypes.SET_CONTACT_FORM_TYPE_SEARCH:
            return {
                ...state,
                searchParams: payload
            }
        case ContactFormTypes.GET_CONTACT_FORM_TYPE_ID_SUCCESS:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}
