import {IContactForm} from "../types/contact-form";
import {IActionCreator} from "../../../store/types/store.types";
import {ContactFormTypes} from "./action-types";

const initialState = {
    contactFormList: [],
    searchParams: ''
}

export const ContactFormReducer = (state = initialState, {type, payload}: IActionCreator) => {
    switch (type) {
        case ContactFormTypes.GET_CONTACT_FORM_SUCCESS:
            return {
                ...state,
                contactFormList: payload
            }
        case ContactFormTypes.SET_CONTACT_SEARCH:
            return {
                ...state,
                searchParams: payload
            }
        default:
            return state;
    }
}