import React, {Component, Suspense} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {ICustomSwitchProps} from "./types/custom-switch";
class CustomSwitchComponent extends Component<ICustomSwitchProps> {
    render() {
        return (
            // loading component should be added instead of null
            <Suspense fallback={null}>
            <Switch>
                {this.props.children}
                {this.props.from && this.props.to ? (
                    <Route exact path={`/${this.props.from}`}>
                        <Redirect to={`/${this.props.from}/${this.props.to}`}/>
                    </Route>
                ) : null}
                <Route path='*'>
                    <Redirect to='/error/not-found' />
                </Route>
            </Switch>
            </Suspense>

        );
    }
}

export default CustomSwitchComponent;
