import { IPaging } from "store/types/store.types";
import { IOrganisation } from "../organisation.types";
import { ITableItems } from "../../../packages/RCRUD/types";

export class OrganisationModel {
    public items: ITableItems[] | null = null;
    public paging: IPaging | null = null;

    constructor(item: any) {
        this._setItems(item.organisations);
        this._setPaging(item.organisations);
    }

    /**
     * Set items
     * @param items
     * @private
     */

    private _setItems({ items }: any): void {
        const newItems: ITableItems[] = []
        items.map((item: IOrganisation) => {
            newItems.push({
                OrganisationPhoto: item.organisationPhoto,
                content: item.organisationTranslations,
                id: item.id
            })
        })
        this.items = newItems;
    }

    /**
     * Set paging items
     * @param paging
     * @private
     */

    private _setPaging({
        hasNextPage,
        hasPreviousPage,
        pageIndex,
        totalCount,
        totalPages
    }: IPaging): void {

        this.paging = {
            hasNextPage,
            hasPreviousPage,
            pageIndex,
            totalCount,
            totalPages
        };
    }

}