import { loading } from "core/layouts/public/store/actions";
import { Dispatch } from "redux"
import { IActionCreator, IPageParams } from "store/types/store.types"
import { container } from "tsyringe";
import { IOrganisation } from "../organisation.types";
import { OrganisationServices } from "../services/organisations.service";
import { OrganisationsTypes } from "./action-types";
import history from "core/configs/history.config";
import Swal from "sweetalert2";

const service = container.resolve(OrganisationServices);


export const getOrganisationsSuccess = (organisation: IOrganisation[]) => ({
    type: OrganisationsTypes.GET_ORGANISATIONS_SUCCESS,
    payload: organisation
})

export const getOrganisationsError = () => ({
    type: OrganisationsTypes.GET_ORGANISATIONS_ERROR,
})

export const getOrganisation = (params: IPageParams, searchParams:any) => (
    (dispatch: Dispatch<IActionCreator>) => {
        dispatch(loading(true))
        service.getOrganisation(params, searchParams)
            .then(res => {
                dispatch(getOrganisationsSuccess(res))
            }).catch(err => {
                dispatch(getOrganisationsError())
            }
            ).then(() =>
                dispatch(loading(false))
            )
    }
)

export const getOrganisationByIdSuccess = (organisatoins: IOrganisation[]) => ({
    type: OrganisationsTypes.GET_ORGANISATION_ID_SUCCESS,
    payload: organisatoins
})

export const getOrganisationyById = (id: string | number) => (
    (dispatch: Dispatch<IActionCreator>) => {
        dispatch(loading(true))
        service.getOrganisationId(id)
            .then(res => {
                dispatch(getOrganisationByIdSuccess(res))
            }).catch(err =>
                console.error(err)
            ).then(() =>
                dispatch(loading(false))
            )

    }

)


export const postOrganisation = (data: any) => (
    (dispatch: Dispatch<any>) => {
        dispatch(loading(true))
        service.postOrganisation(data)
            .then(res => {
                if (res.status === 201) {
                    Swal.fire({
                        title: 'Əlavə olundu.',
                        icon: 'success',
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    history.goBack()
                }
            }).catch(err =>
                console.error(err, "ERRRORR")
            ).then(() => {
                dispatch(loading(false))
                history.goBack();
            }
            )
    }
)

export const putOrganisation = (data: any, id: number | undefined) => (
    (dispatch: Dispatch<any>) => {
        dispatch(loading(true))
        service.putOrganisation(data, id)
            .then((res) => {
                if (res.status === 204) {
                    Swal.fire({
                        title: 'Dəyişiklik qeydə alındı.',
                        icon: 'success',
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    history.goBack()
                }
            }).catch(err =>
                console.error(err)
            ).then(() =>
                dispatch(loading(false))
            )
    }
)


export const deleteOrganisation = (id: number) => (
    (dispatch: Dispatch<any>) => {
        dispatch(loading(true))
        service.deleteOrganisation(id)
            .then(res => {
                dispatch(getOrganisation({ PageSize: 10, PageNumber: 1 }, ''))
            }).catch(err =>
                console.error(err)
            ).then(() =>
                dispatch(loading(false))
            )
    }
)

export const setSearch = (data: any) => (
    {
        type: OrganisationsTypes.SET_SEARCH,
        payload: data
    }
)
