import { IActionCreator } from "store/types/store.types";
import { IServiceReduxState } from "../service.types";
import { ServiceTypes } from "./actions-types";

const initialState: IServiceReduxState = {
  // items: null,
  // item: null,
  allCategories:[],
  allOrganisation:[],
  servicesList: [],
  serviceTypeList: [],
  allTags: [],
  serviceById: null,
  fileTypes: [],
  relationsService: [],
  loading: true,
  error: null,
  totalPages: 1,
  searchParams: ''
};

export const ServiceReducer = (
  state = initialState,
  { type, payload }: IActionCreator
) => {
  switch (type) {
    case ServiceTypes.SET_SERVICE_SEARCH:
      return {
        ...state,
        searchParams: payload
      }
    case ServiceTypes.GET_FILETYPES_START:
      return {
        ...state,
        loading: true
      }
    case ServiceTypes.GET_FILETYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        fileTypes: [...payload]
      }
    case ServiceTypes.GET_FILETYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.GET_RELATIONS_SERVICES_START:
      return {
        ...state,
        loading: true
      }
    case ServiceTypes.GET_RELATIONS_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        relationsService: payload
      }
    case ServiceTypes.GET_RELATIONS_SERVICES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.GET_ALLCATEGORIES_START:
      return {
        ...state,
        loading: true,
      }
    case ServiceTypes.GET_ALLCATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        allCategories: payload
      }
    case ServiceTypes.GET_ALLCATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.GET_SERVICE_BY_ID_START:
      return {
        ...state,
        loading: true,
      }
    case ServiceTypes.GET_SERVICE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceById: payload
      }
    case ServiceTypes.GET_SERVICE_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.GET_ALLORGANISATONS_START:
      return {
        ...state,
        loading: true,
      }
    case ServiceTypes.GET_ALLORGANISATONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOrganisation: payload
      }
    case ServiceTypes.GET_ALLORGANISATONS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.GET_ALL_TAGS_START:
      return {
        ...state,
        loading: true,
      }
    case ServiceTypes.GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTags: payload
      }
    case ServiceTypes.GET_ALL_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.POST_SERVICE_START:
      return {
        ...state,
        loading: true,
      }
    case ServiceTypes.POST_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case ServiceTypes.POST_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.PUT_SERVICE_START:
      return {
        ...state,
        loading: true,
      }
    case ServiceTypes.PUT_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case ServiceTypes.PUT_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.DELETE_SERVICE_START:
      return {
        ...state,
        loading: true,
      }
    case ServiceTypes.DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case ServiceTypes.DELETE_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.GET_SERVICE_TYPE_START:
      return {
        ...state,
        loading: true,
      }
    case ServiceTypes.GET_SERVICE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceTypeList: payload
      }
    case ServiceTypes.GET_SERVICE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ServiceTypes.GET_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        servicesList: payload.items,
        totalPages: payload.totalPages
      };
    case ServiceTypes.GET_SERVICE_BY_ID_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
