import React, { useEffect } from 'react';
import 'reflect-metadata';
import { Router } from 'react-router-dom';
import { LayoutRoutes } from './router/router';
import { DbService } from './assets/db/db.service';
import store from "./store/store.worker";
import { Provider } from "react-redux";
import 'antd/dist/antd.css';
import './App.scss';
import history from "core/configs/history.config";


// import { wrapStore } from "redux-in-worker";
import { container } from 'tsyringe';
import { ApiInterceptor, ApiInterceptorResponse } from 'core/interceptors/api.interceptor';


// const worker = new Worker(new URL('./store/store.worker', import.meta.url));
// const workerStore = wrapStore(worker, store.getState());
function App() {
    useEffect(() => {

        // tslint:disable-next-line: no-unused-expression
        new DbService();
    }, [])
    const interceptorRes = container.resolve(ApiInterceptorResponse)
    const interceptor = container.resolve(ApiInterceptor)

    return (
        <Provider store={store}>
            <Router history={history}>
                <LayoutRoutes />
            </Router>
        </Provider>
    );
}

export default App;
