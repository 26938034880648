import { ILang } from "core/layouts/public/types/public";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import { RootState } from "store/types/store.types";
import { contentKeys, staticKeys } from "../contactTypes.types";
import './secondary.component.scss';
import {
  getContactType,
  getContactTypeById,
  putContactType,
} from "../store/actions";
import FormComponent from "../../../packages/RCRUD/form.component";
import { FormStatus } from "../../../packages/RCRUD/types";
import ButtonComponent from "../../../packages/RButton/button.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CardComponent from "../../../packages/RCard/card.component";
import {Input} from "antd";
import {DeleteIco} from "../../../assets/customIcons";
import {setBreadCrumb} from "../../../core/layouts/public/store/actions";
import {checkInputValidation} from "../../../core/helpers/common-functions/common-functions";
import toast, {Toaster} from "react-hot-toast";

const EditComponent = (props: any) => {
  const history = useHistory();
  const { search } = useLocation();
  const [id, setId] = useState<number>();
  const [value, setValue] = useState<any>({});
  const langs = useSelector((state: RootState) => state.publicState.langs);
  const item = useSelector((state: RootState) => state.contactType.item);
  const [isShowButton, setIsShowButton] = useState(false);
  const [newFileUploaded, setNewFileUploaded] = useState(false);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState<any>({});
  const [nameTitle, setNameTitle] = useState({
    value: '',
    validation: {
      required : {
        value : true,
        errorText : `Məlumat əlavə edin`
      },
      maxLength: {
        value: 200,
        errorText: 'Simvol sayı maksimum 200 olmalıdır'
      },
    },
    currentErrTxt: '',
    touched: false,
    isValid: true
  });
  const [fileItem, setFileItem] = useState('');
  const [imageUploaded, setImageUploaded] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    const idEdit = search?.split("?")[1];
    if (idEdit) {
      setId(parseInt(idEdit));
      dispatch(getContactTypeById(parseInt(idEdit)));
    }
    if(props){
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props.crumbs.forEach((nameList: any) => {
        if(nameList.path === '/app/contactType/edit'){
          dispatch(setBreadCrumb(nameList.name))
        }
      })
    }
  }, [dispatch, search]);

  useEffect(() => {
    dispatch(getContactType());
  }, [dispatch]);



  useEffect(() => {
    if (id) {
      // setInputs({
      //   name: {
      //     type: "text",
      //     label: "name",
      //     value: item ? item?.name : "",
      //     second_label: "",
      //     validation: {
      //       required: {
      //         value: true,
      //         errorText: `Name  can't be empty`,
      //       },
      //     },
      //     currentErrTxt: "",
      //     touched: false,
      //     isValid: false,
      //   },
      //   contactTypePhoto: {
      //     type: "file",
      //     label: "contactTypePhoto",
      //     value: item ? item?.contactTypePhoto : "",
      //     validation: {
      //       required: {
      //         value: true,
      //         errorText: `Icon  can't be empty`,
      //       },
      //     },
      //     currentErrTxt: "",
      //     touched: false,
      //     isValid: false,
      //   },
      // });

      item ? setNameTitle((prev: any) => {
        return {
          ...prev,
          value: item.name
        }
      }) : setNameTitle((prev: any) => {
        return {
          ...prev,
          value: ''
        }
      })
      item ? setImageUploaded(item.contactTypePhoto) : setImageUploaded('')
      item ? setFileItem(item.contactTypePhoto) : setFileItem('')
    }
  }, [item, id]);

  const deleteImage = () => {
    setImageUploaded('')
    setFileItem('')
  }

  useEffect(() => {
    checkFormValid()
  },[nameTitle, fileItem])

  const checkFormValid = () => {
    let valid = true;

    valid = nameTitle.value !== '' && valid;
    valid = nameTitle.isValid && valid;
    valid = fileItem !== '' && valid;

    setFormIsValid(valid);
  }

  const handleUpload = (e: any) => {
    let pattern = ['image/png','image/x-icon','image/svg+xml']

    if(pattern.includes(e.target?.files[0]?.type)){
      let result = URL.createObjectURL(e.target.files[0])

      setFileItem(e.target.files[0])
      setNewFileUploaded(true)

      setImageUploaded(result);
      setIsShowButton(true)
    }else {
      toast.error(`File tipini düzgün seçin`)
      return false;
    }

  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let formData = new FormData();
    let nullableFile: any = null;



    if(newFileUploaded){
      formData.append('ContactTypePhoto', fileItem)
    }else {
      formData.append('ContactTypePhoto', nullableFile)
    }
    formData.append('Name', nameTitle.value)

    if(formIsValid){
      dispatch(putContactType(formData,id))
    }else {
      return false;
    }
  }

  const handleInput = (e: any) => {
    setNameTitle((prev: any) => {
      const inputValid = checkInputValidation(e, nameTitle.validation, nameTitle, prev)
      return {
        ...prev,
        value: e,
        isValid: inputValid
      }
    })
    setIsShowButton(true)
  }


  return (
    <div className={'category'}>
      <ButtonComponent
          classNames={"px-30 py-10 back-btn"}
          type={"button"}
          color={"primary"}
          outline={true}
          click={() => history.goBack()}
      >
        <FontAwesomeIcon icon="arrow-left" />
        Geri
      </ButtonComponent>
      <CardComponent>
        <Toaster />
        <div className="category__body">
          <div className="container">
            <form action="" onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="col-12">
                  <div className={'category__group'}>
                    <label htmlFor="">Ad</label>
                    <Input className={`py-10 px-5 ${nameTitle.isValid ? '' : 'category__group__err'}`} type={'text'} value={nameTitle.value} onChange={(e) => handleInput(e.target.value)}/>
                    <span className={`${nameTitle.isValid ? 'category__group__success' : 'category__group__error'}`}>{nameTitle.currentErrTxt}</span>
                  </div>
                </div>
              </div>
              <div className={'row mt-25'}>
                <div className="col-12">
                  <div className={'category__group'}>
                    <label htmlFor="">Şəkil (ico,svg,png)</label>
                    {
                      imageUploaded ? <div className={'category__pic'}>
                        <img src={imageUploaded} alt=""/>
                        <div onClick={() => deleteImage()}>
                          <DeleteIco/>
                        </div>
                      </div>: <div className={'category__upload'}>
                        <label htmlFor="upload">Şəkil əlavə et</label>
                        <input type="file" hidden id={'upload'} onChange={(e) => handleUpload(e)}/>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className={'category__btns mt-25'}>
                {
                  !id ?                 <ButtonComponent disabled={!formIsValid} type={'submit'} classNames={'btn btn-primary'}>Əlavə et</ButtonComponent> : <>
                    {
                      id && isShowButton ?                 <ButtonComponent disabled={!formIsValid} type={'submit'} classNames={'btn btn-primary'}>Əlavə et</ButtonComponent> : null
                    }
                  </>
                }
                <ButtonComponent click={() => history.goBack()} type={'button'} classNames={'btn btn-outline-secondary'}>İmtina</ButtonComponent>
              </div>
            </form>
          </div>
        </div>
      </CardComponent>
    </div>
  );
};

export default EditComponent;
