import { IActionCreator } from 'store/types/store.types';
import { IOrganisationsReduxState } from '../organisation.types';
import { OrganisationsTypes, } from "./action-types";

const initialState: IOrganisationsReduxState = {
    items: null,
    paging: null,
    item: null,
    searchParams: ''
}

export const OrganisationsReducer = (state = initialState, { type, payload }: IActionCreator) => {
    switch (type) {
        case OrganisationsTypes.GET_ORGANISATIONS_SUCCESS:
            return {
                ...state,
                ...payload
            }
        case OrganisationsTypes.SET_SEARCH:
            return {
                ...state,
                searchParams: payload
            }
        case OrganisationsTypes.GET_ORGANISATION_ID_SUCCESS:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}
