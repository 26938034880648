import { API } from "core/configs/api.config";
import { getToken } from "core/configs/auth.config";
import { DELETE } from "packages/VHttp/DELETE";
import { GET } from "packages/VHttp/GET";
import { POST } from "packages/VHttp/POST";
import { PUT } from "packages/VHttp/PUT";
import { container, injectable } from "tsyringe";
import { ContactTypeModel } from "../models/contactTypes.model";
import { ContactTypeByIdModel } from "../models/contactTypesById.model";

@injectable()
export class ContactTypeServices {
  private _get: GET = container.resolve(GET);
  private _post: POST = container.resolve(POST);
  private _put: PUT = container.resolve(PUT);
  private _delete: DELETE = container.resolve(DELETE);

  getContactType(): Promise<any> {
    return this._get
      .setApi(API.contactTypes)
      .requestPromise()
      .then((res) => {
        return new ContactTypeModel(res.data);
      });
  }
  getContactTypeId(id: number | string): Promise<any> {
    return (
      this._get
        .setApi(API.contactTypes + "/" + id)
        .setParams(id)
        .requestPromise()
        .then((res) => {
          return new ContactTypeByIdModel(res.data);
        })
    );
  }

  putContactType(data: any, id: number | undefined): Promise<any> {
    return this._put
      .setApi(API.contactTypes + `/${id}`)
      .setPayload(data)
      .requestPromise()
      // .then((res) => {
      //   // return new ContactTypeModel(res.data);
      // });
  }
}
