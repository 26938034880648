import { Dispatch } from "redux";
import { IActionCreator } from "store/types/store.types";
import { container } from "tsyringe";
import { PublicServices } from "../services/public.service";
import { ILang } from "../types/public";
import { LeftMenuActionTypes, publicConstants } from "./action-types";

const service = container.resolve(PublicServices);

export const toggleLeftMenu = (isActive: boolean) => {
    return {
        type: LeftMenuActionTypes.TOGGLE_LEFT_MENU,
        payload: isActive
    }
}

export const loading = (loading: boolean) => ({
    type: publicConstants.LOADING,
    payload: loading
})

export const langSuccess = (data: ILang[]) => ({
    type: publicConstants.LANG_SUCCESS,
    payload: data
})

export const langGet = () => (
    (dispatch: Dispatch<IActionCreator>) => {
        service.getLang()
            .then(res => {
                dispatch(langSuccess(res.data))
            }).catch(err =>
                console.error(err)
            )
    }
)

export const langToggle = (data: any) => ({
    type: publicConstants.LANG_TOGGLE,
    payload: data
})

export const setBreadCrumb = (data: any) => ({
    type: publicConstants.SET_CRUMB,
    payload: data
})