import ReadComponent from 'packages/RCRUD/read.component'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IPageParams, RootState } from 'store/types/store.types'
import {deleteOrganisation, getOrganisation, setSearch} from './store/actions'
import { contentKeys, staticKeys } from './organisation.types'
import PaginationComponent from 'packages/RCRUD/pagination.component'
import AlertComponents from 'packages/RAlert/alert.components'
import { setBreadCrumb } from "../../core/layouts/public/store/actions";
import FilterComponent from 'packages/RCRUD/filter.component'
import { useHistory, useLocation } from 'react-router-dom'
import SearchIcon from "../../assets/images/search-icon.svg";
import debounce from "lodash.debounce";
import './organisations.component.scss';

const OrganisationsComponent = (props: any) => {

  const [pageParams, setPageParams] = useState<IPageParams>({ PageSize: 10, PageNumber: 1 })

  const organisations = useSelector((state: RootState) => state.organisations)
  const langActive = useSelector((state: RootState) => state.publicState.langsActive);
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const filterHandler = (value: string) => {
    setName(value)
    setPageParams({
      PageSize: 10,
      PageNumber: 1,
    })
  }

  useEffect(() => {
    // dispatch(getOrganisation(pageParams, name))
    if (props) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props.crumbs.forEach((nameList: any) => {
        dispatch(setBreadCrumb(nameList.name))
      })
    }
  }, [dispatch, name, props])

  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    setName(organisations.searchParams)
    let urlPageIndex: any = history.location.search.split('?')[1]
    setPageParams({ PageSize: 10, PageNumber: urlPageIndex ? urlPageIndex : 1 })
    dispatch(getOrganisation({ PageSize: 10, PageNumber: urlPageIndex ? urlPageIndex : 1 }, {orgName: organisations.searchParams}))
  }, [dispatch, history, langActive])

  useEffect(() => {
    const pageNumber = search.split('?')[1]
    setPageParams({ PageSize: 10, PageNumber: pageNumber ? +pageNumber : 1 })
  }, [search])

  const handleChange = debounce((e: any) => {
    setName(e.target.value)
    let urlPageIndex: any = history.location.search.split('?')[1]
    dispatch(getOrganisation({ PageSize: 10, PageNumber: urlPageIndex ? urlPageIndex : 1 }, {orgName: e.target.value}))
    dispatch(setSearch(e.target.value))
  }, 200)

  return (
    <div>
      <div className={'search'}>
        <input type="text" defaultValue={name} onInput={(e) => handleChange(e)} />
        <img src={SearchIcon} alt="" />
      </div>
      <ReadComponent
        pageParams={pageParams}
        showEdit={true}
        data={organisations.items} contentKeys={contentKeys} staticKeys={staticKeys} deleteItem={(id: number) => dispatch(deleteOrganisation(id))} />

      {
        organisations.paging && <PaginationComponent
          totalCount={organisations.paging?.totalCount}
          totalPages={organisations.paging?.totalPages}
          prev={organisations.paging?.hasPreviousPage}
          next={organisations.paging?.hasNextPage}
          changeCount={(page: number) => {
            setPageParams({ PageSize: 10, PageNumber: page })
            history.push(`?${page}`);
          }}
          pageIndex={organisations.paging?.pageIndex}
          PageSize={pageParams.PageSize}
        />
      }
    </div>
  )
}

export default OrganisationsComponent