import { IPaging } from "store/types/store.types";
import { IContactType } from "../contactTypes.types";
import { ITableItems } from "../../../packages/RCRUD/types";

export class ContactTypeByIdModel {
    public item: {}| null = null;

    constructor(item: any) {
        this._setItems(item);
    }

    /**
     * Set items
     * @param items
     * @private
     */

     private _setItems({contactType}: any): void {
        this.item = contactType;
    }


}
