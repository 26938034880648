import { SyntheticEvent, useEffect } from 'react';
import { IModalProps } from './types/modal';
import './modal.component.scss';
import LoadingComponent from '../RLoading/loading.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ModalComponent = (props: IModalProps) => {

    return (
            <>
            <div className={`opt-modal fade ${props.show ? 'show' : ''} `}>
                <div className='opt-modal-overlay' onClick={() => {
                    props.setShow(false)
                    document.body.style.overflow = 'auto'
                }}>
                    <div onClick={(e: SyntheticEvent) => e.stopPropagation()} className={`opt-modal--${props.size} ${props.classes || ''} opt-modal__dialog opt-modal__dialog--${props.position}`}>
                        <div className='opt-modal__content'>
                            {props.loading ? <LoadingComponent scoped={true} /> : null}
                            {!props.hideHeader &&
                                <div className='opt-modal__header p-12'>
                                    <h5 className='opt-modal__title'>{props.title}</h5>
                                    <button
                                        onClick={() => {
                                            props.setShow(false)
                                            document.body.style.overflow = 'auto'
                                        }
                                        }
                                        type='button'
                                        className='opt-modal__close'
                                    >
                                        {
                                            props.closeIcon ?
                                                props.closeIcon
                                                :
                                                <FontAwesomeIcon icon="times" size='xs'/>
}
                                        </button>
                                    </div>
                                }
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
};

export default ModalComponent;
