import { IPaging } from "store/types/store.types";
import { IOrganisation } from "../organisation.types";
import { ITableItems } from "../../../packages/RCRUD/types";

export class OragnisationByIdModel {
    public item: ITableItems | null = null;

    constructor(item: any) {
        this._setItem(item.organisation);
    }

    /**
     * Set item
     * @param item
     * @private
     */

    private _setItem(item: any): void {
        this.item = {
            OrganisationPhoto: item.organisationPhoto,
            content: item.organisationTranslations,
            id: item.id
        }
    }

}
