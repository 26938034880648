import React from "react";

export const EditIco = ({className = ''}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="blue"
            className="bi bi-pencil"
            viewBox="0 0 16 16"
        >
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
        </svg>
    )
}

export const DeleteIco = ({className = ''}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="red"
            className="bi bi-trash"
            viewBox="0 0 16 16"
        >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path
                fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
        </svg>
    )
}

export const DetailsIco = ({className = ''}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" viewBox="0 0 192 512">
            <path d="M160 448h-32V224c0-17.69-14.33-32-32-32L32 192c-17.67 0-32 14.31-32 32s14.33 31.1 32 31.1h32v192H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32S177.7 448 160 448zM96 128c26.51 0 48-21.49 48-48S122.5 32.01 96 32.01s-48 21.49-48 48S69.49 128 96 128z"/>
        </svg>
    )
}

export const LeftArrowIco = ({className = ''}) => {
    return (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left"
             className="svg-inline--fa fa-arrow-left fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 448 512">
            <path fill="currentColor"
                  d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
        </svg>
    )
}

export const RightArrowIco = ({className = ''}) => {
    return (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
             className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 448 512">
            <path fill="currentColor"
                  d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
        </svg>
    )
}

export const DeleteListIcon = ({className = ''}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <path d="M0.645978 0.645917C0.692424 0.599354 0.747599 0.562411 0.808344 0.537204C0.86909 0.511998 0.934211 0.499023 0.999978 0.499023C1.06575 0.499023 1.13087 0.511998 1.19161 0.537204C1.25236 0.562411 1.30753 0.599354 1.35398 0.645917L3.99998 3.29292L6.64598 0.645917C6.69247 0.599429 6.74765 0.562553 6.80839 0.537394C6.86913 0.512234 6.93423 0.499285 6.99998 0.499285C7.06572 0.499285 7.13082 0.512234 7.19156 0.537394C7.2523 0.562553 7.30749 0.599429 7.35398 0.645917C7.40047 0.692405 7.43734 0.747594 7.4625 0.808333C7.48766 0.869073 7.50061 0.934173 7.50061 0.999917C7.50061 1.06566 7.48766 1.13076 7.4625 1.1915C7.43734 1.25224 7.40047 1.30743 7.35398 1.35392L4.70698 3.99992L7.35398 6.64592C7.40047 6.6924 7.43734 6.74759 7.4625 6.80833C7.48766 6.86907 7.50061 6.93417 7.50061 6.99992C7.50061 7.06566 7.48766 7.13076 7.4625 7.1915C7.43734 7.25224 7.40047 7.30743 7.35398 7.35392C7.30749 7.4004 7.2523 7.43728 7.19156 7.46244C7.13082 7.4876 7.06572 7.50055 6.99998 7.50055C6.93423 7.50055 6.86913 7.4876 6.80839 7.46244C6.74765 7.43728 6.69247 7.4004 6.64598 7.35392L3.99998 4.70692L1.35398 7.35392C1.30749 7.4004 1.2523 7.43728 1.19156 7.46244C1.13082 7.4876 1.06572 7.50055 0.999978 7.50055C0.934234 7.50055 0.869134 7.4876 0.808394 7.46244C0.747655 7.43728 0.692466 7.4004 0.645978 7.35392C0.59949 7.30743 0.562614 7.25224 0.537455 7.1915C0.512296 7.13076 0.499346 7.06566 0.499346 6.99992C0.499346 6.93417 0.512296 6.86907 0.537455 6.80833C0.562614 6.74759 0.59949 6.6924 0.645978 6.64592L3.29298 3.99992L0.645978 1.35392C0.599415 1.30747 0.562472 1.2523 0.537265 1.19155C0.512059 1.13081 0.499084 1.06568 0.499084 0.999917C0.499084 0.93415 0.512059 0.869028 0.537265 0.808283C0.562472 0.747538 0.599415 0.692363 0.645978 0.645917Z" fill="black"/>
        </svg>
    )
}