import { IPaging } from "store/types/store.types"

export interface IOrganisationsReduxState {
    items: IOrganisation[] | null
    item: IOrganisation | null
    paging: IPaging[] | null
    searchParams: string
}

export const contentKeys = ["name", "description"]
export const staticKeys = ["OrganisationPhoto"]


export interface IOrganisation {
    organisationPhoto: string
    organisationTranslations: IOrganisationContent[]
    id: number
}

export interface IOrganisationContent {
    languageId: number,
    name: string
}

