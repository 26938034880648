import { IActionCreator } from 'store/types/store.types';
import { ITagsReduxState } from '../tag.types';
import { TagsTypes, } from "./action-types";

const initialState: ITagsReduxState = {
    items: null,
    paging: null,
    item: null,
    searchParams: ''
}

export const TagReducer = (state = initialState, { type, payload }: IActionCreator) => {
    switch (type) {
        case TagsTypes.GET_TAGS_SUCCESS:
            return {
                ...state,
                ...payload
            }
        case TagsTypes.SET_TAG_SEARCH:
            return {
                ...state,
                searchParams: payload
            }
        case TagsTypes.GET_TAG_ID_SUCCESS:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}
