import { environment } from './app.config';

const baseUrl = "v2/"
const dropdownUrl = "v2/dropdown/"

export const API = {
    userLists: environment.apiMain + 'users',
    test: environment.apiMain + 'test',
    login: environment.apiMain + baseUrl + 'login',
    register: environment.apiMain + baseUrl + 'register',
    categories: environment.apiMain + baseUrl + 'category',
    organisation:environment.apiMain + baseUrl + 'organisation',
    faq: environment.apiMain + baseUrl + 'faq',
    tag: environment.apiMain + baseUrl + 'tag',
    lang: environment.apiMain + baseUrl + 'dropdown/languages',
    about:environment.apiMain + baseUrl + 'about',
    contactTypes :environment.apiMain + baseUrl + 'contactType',
    contact :environment.apiMain + baseUrl + 'contact',
    keywords:environment.apiMain + baseUrl + 'keyword',
    keywordTypes:environment.apiMain + dropdownUrl + 'keywordTypes',
    content:environment.apiMain + baseUrl + 'content',
    contentTypes:environment.apiMain + baseUrl + 'dropdown/contentTypes',
    service:environment.apiMain + baseUrl + 'service',
    allOrganisation:environment.apiMain + dropdownUrl + 'allOrganisations',
    allCategories:environment.apiMain + dropdownUrl + 'allCategories',
    fileTypes: environment.apiMain + dropdownUrl + 'fileTypes',
    allTags: environment.apiMain + dropdownUrl + 'allTags',
    serviceTypes: environment.apiMain + baseUrl + 'dropdown/serviceTypes',
    dropDownContactTypes: environment.apiMain + dropdownUrl + 'contactTypes',
    relationService: environment.apiMain + dropdownUrl + 'services',
    contactForm: environment.apiMain + baseUrl + 'contactForm',
    contactFormType: environment.apiMain + baseUrl + 'contactFormType',

}
