export enum ServiceTypes {
    GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS",
    GET_SERVICE_BY_ID_START = "GET_SERVICE_BY_ID_START",
    GET_SERVICE_BY_ID_SUCCESS = "GET_SERVICE_BY_ID_SUCCESS",
    GET_SERVICE_BY_ID_FAIL = "GET_SERVICE_BY_ID_FAIL",
    GET_FILETYPES_START = 'GET_FILETYPES_START',
    GET_FILETYPES_SUCCESS = 'GET_FILETYPES_SUCCESS',
    GET_FILETYPES_FAIL = 'GET_FILETYPES_FAIL',
    GET_ALLCATEGORIES_START = 'GET_ALLCATEGORIES_START',
    GET_ALLCATEGORIES_SUCCESS = 'GET_ALLCATEGORIES_SUCCESS',
    GET_ALLCATEGORIES_FAIL = 'GET_ALLCATEGORIES_FAIL',
    GET_ALLORGANISATONS_START = 'GET_ALLORGANISATONS_START',
    GET_ALLORGANISATONS_SUCCESS = 'GET_ALLORGANISATONS_SUCCESS',
    GET_ALLORGANISATONS_FAIL = 'GET_ALLORGANISATONS_FAIL',
    GET_ALL_TAGS_START = 'GET_ALL_TAGS_START',
    GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS',
    GET_ALL_TAGS_FAIL = 'GET_ALL_TAGS_FAIL',
    GET_SERVICE_TYPE_START = 'GET_SERVICE_TYPE_START',
    GET_SERVICE_TYPE_SUCCESS = 'GET_SERVICE_TYPE_SUCCESS',
    GET_SERVICE_TYPE_FAIL = 'GET_SERVICE_TYPE_FAIL',
    POST_SERVICE_START = 'POST_SERVICE_START',
    POST_SERVICE_SUCCESS = 'POST_SERVICE_SUCCESS',
    POST_SERVICE_FAIL = 'POST_SERVICE_FAIL',
    PUT_SERVICE_START = 'PUT_SERVICE_START',
    PUT_SERVICE_SUCCESS = 'PUT_SERVICE_SUCCESS',
    PUT_SERVICE_FAIL = 'PUT_SERVICE_FAIL',
    DELETE_SERVICE_START = 'DELETE_SERVICE_START',
    DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS',
    DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL',
    GET_RELATIONS_SERVICES_START = 'GET_RELATIONS_SERVICES_START',
    GET_RELATIONS_SERVICES_SUCCESS = 'GET_RELATIONS_SERVICES_SUCCESS',
    GET_RELATIONS_SERVICES_FAIL = 'GET_RELATIONS_SERVICES_FAIL',
    SET_SERVICE_SEARCH = "SET_SERVICE_SEARCH"
}