import { combineReducers } from "redux";
import { publicReducer } from "../core/layouts/public/store/reducers";
import { authReducer } from "../core/layouts/auth/store/reducers";
import { CategoryReducer } from "pages/categories/store/reducer";
import { TagReducer } from "pages/tag/store/reducer";
import { OrganisationsReducer } from "pages/organisations/store/reducer";
import { FaqReducer } from "pages/faq/store/reducer";
import { AboutReducer } from "pages/about/store/reducer";
import { ContactTypeReducer } from "pages/contactTypes/store/reducer";
import { ContactReducer } from "pages/contacts/store/reducer";
import { KeywordReducer } from "pages/keywords/store/reducer";
import { ContentReducer } from "pages/content/store/reducer";
import { ServiceReducer } from "pages/service/store/reducer";
import {ContactFormReducer} from "../pages/contact-form/stroe/reducer";
import {contactFormTypeReducer} from "../pages/contact-form-types/store/reducer";

export const rootReducer = combineReducers({
  publicState: publicReducer,
  auth: authReducer,
  category: CategoryReducer,
  organisations: OrganisationsReducer,
  faq: FaqReducer,
  tag: TagReducer,
  about: AboutReducer,
  contactType: ContactTypeReducer,
  contact: ContactReducer,
  keyword:KeywordReducer,
  content:ContentReducer,
  service:ServiceReducer,
  contactForm: ContactFormReducer,
  contactFormType: contactFormTypeReducer
});
