import { IActionCreator } from 'store/types/store.types';
import { IFaqsReduxState } from '../faq.types';
import { FaqsTypes, } from "./action-types";

const initialState: IFaqsReduxState = {
    items: null,
    paging: null,
    item: null,
    faqByIdList: null,
    searchParams: ''
}

export const FaqReducer = (state = initialState, { type, payload }: IActionCreator) => {
    switch (type) {
        case FaqsTypes.GET_FAQS_SUCCESS:
            return {
                ...state,
                ...payload
            }
        case FaqsTypes.SET_FAQ_SEARCH:
            return {
                ...state,
                searchParams: payload
            }
        case FaqsTypes.GET_FAQ_ID_SUCCESS:
            return {
                ...state,
                faqByIdList: payload
            }
        default:
            return state;
    }
}
