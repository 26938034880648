import { IActionCreator } from "store/types/store.types";
import { IContactReduxState } from "../contact.types";
import { ContactTypes } from "./actions-types";

const initialState: IContactReduxState = {
  items: null,
  item: null,
  loading: true,
  contactTypeList: [],
  contactByIdList: null,
  error: null
};

export const ContactReducer = (
  state = initialState,
  { type, payload }: IActionCreator
) => {
  switch (type) {
    case ContactTypes.GET_CONTACT_TYPES_START:
      return {
        ...state,
        loading: true
      }
    case ContactTypes.GET_CONTACT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        contactTypeList: [...payload]
      }
    case ContactTypes.GET_CONTACT_TYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case ContactTypes.GET_CONTACT_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case ContactTypes.GET_CONTACT_BY_ID_SUCCESS:
      return {
        ...state,
        contactByIdList: payload
      };
    default:
      return state;
  }
};
